import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import PlaceSearchHelper from '@/helpers/placeSearchHelper';
import { bookingService, dateHelper, loginHelper, loginService, searchService, siteService } from '@/main';
import { SearchPlaceTypeParamsInterface } from '@/models/Interfaces';
import MapMarker from '@/models/Map/MapMarker';
import MapPlace, { MapPlaceAvailability } from '@/models/Map/MapPlace';
import MapPlaceType from '@/models/Map/MapPlaceType';
import MapResponse from '@/models/Map/MapRespone';
import { sitesModule } from '@/store/modules/site';
import { BoundsExpression } from 'leaflet';
import MapSettingsModalComponent from '@/components/map/settings-modal';
import MapImageModalComponent from '@/components/map/image-modal';
import MapSettings from '@/models/Map/MapSettings';
import MapImage from '@/models/Map/MapImage';
import MapPlacePopup from '@/models/Map/MapPlacePopup';
import Booking from '@/models/Booking';
import BookingPlace from '@/models/Booking/BookingPlace';
import to from 'await-to-js';
import PrdMap from '@/components/map/map.vue';
import MapSettingsModal from '@/components/map/settings-modal.vue';
import MapImageModal from '@/components/map/image-modal.vue';
import MapPlacePreviewComponent from '@/components/map/place-preview.vue';

@Component({
    components: {
        PrdMap,
        MapSettingsModal,
        MapImageModal,
        PlacePreview: MapPlacePreviewComponent,
    },
})
export default class AccommodationMapPage extends Vue {
    public initMapResponse: MapResponse = null;
    public mapResponse: MapResponse = null;
    public period: any = { fromDate: null, toDate: null };
    public searchParams: SearchPlaceTypeParamsInterface = {
        site: sitesModule.activeSite.siteId,
        siteOwner: loginHelper.getSiteOwner(),
        fromDate: null,
        toDate: null,
        capacity: 0,
        fromCapacity: 1,
        toCapacity: 99,
        facets: '',
        Senior: 0,
        Adult: 0,
        ChildThreeYearsAndUp: 0,
        ZeroUntilTwoYears: 0,
        Pet: 0,
        disabledAccessible: false,
        petsAllowed: false,
    };
    public maxCapacity: number = 0;
    public allFacets: any = {};
    public selectedFacets: any = {};
    public loading: boolean = true;
    public loadingSettings: boolean = true;
    public mapSettings = new MapSettings();
    public editMap: boolean = false;
    public $refs!: {
        mapSettingsModal: MapSettingsModalComponent,
        mapImageModal: MapImageModalComponent,
    };
    public showImage: boolean = true;
    public showMap: boolean = false;
    public showMarkers: boolean = true;
    public selectedPlace = new MapPlacePopup();
    public loadingSelectedPlace = true;
    public MapPlaceAvailability = MapPlaceAvailability;

    private searchHelper = new PlaceSearchHelper();
    private searchTimeout = null;

    public get markers(): MapMarker[] {
        const markers = [];
        const placeIds: number[] = [];
        if (this.mapResponse) {
            this.mapResponse.accommodationTypes.forEach((placeType: MapPlaceType) => {
                placeType.places.forEach((place: MapPlace) => {
                    placeIds.push(place.placeId);
                });
            });
        }

        if (this.initMapResponse) {
            this.initMapResponse.accommodationTypes.forEach((placeType: MapPlaceType) => {
                placeType.places.forEach((place: MapPlace) => {
                    let color = 'green';

                    if (placeIds.indexOf(place.placeId) === -1) {
                        color = 'blue';
                    }  else if (place.availability === MapPlaceAvailability.Available) {
                        color = 'green';
                    } else if (place.availability === MapPlaceAvailability.Booked) {
                        color = 'red';
                    }

                    const props = new MapPlacePopup({
                        ...placeType,
                        placeId: place.placeId,
                        lotNumber: place.lotNumber,
                        siteId: placeType.site,
                        availability: place.availability,
                    } as MapPlacePopup);

                    markers.push({
                        id: placeType.placeTypeId + place.lotNumber,
                        latitude: place.latitude,
                        longitude: place.longitude,
                        title: place.lotNumber,
                        color,
                        props,
                    } as MapMarker);
                });
            });
        }

        return markers;
    }

    public get bounds(): BoundsExpression {
        return [
            [this.mapSettings.boundingBoxTopLat, this.mapSettings.boundingBoxTopLong],
            [this.mapSettings.boundingBoxBottomLat, this.mapSettings.boundingBoxBottomLong],
        ] as BoundsExpression;
    }

    public get image(): MapImage {
        return {
            url: this.mapSettings.url,
            bounds: this.bounds,
        };
    }

    @Watch('searchParams', { deep: true, immediate: true })
    public watchSearchParams(val, old) {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(this.fetchMapData, 250);
    }

    public async mounted() {
        this.period.fromDate = dateHelper.today();
        this.period.toDate = dateHelper.tomorrow();
        this.allFacets = await this.getAllFacets();

        this.loadingSettings = true;
        await this.fetchSettings();
        this.loadingSettings = false;

        await this.fetchInitMap();
        await this.fetchMapData();
    }

    public async getAllFacets() {
        const response = await searchService.facets({
            site: sitesModule.activeSite.siteId,
            siteOwner: loginHelper.getSiteOwner(),
        } as SearchPlaceTypeParamsInterface);

        let biggestCapacity = 0;
        response.data.accommodationTypes.forEach((type) => {
            if (biggestCapacity < type.capacity) {
                biggestCapacity = type.capacity;
            }
        });
        this.maxCapacity = biggestCapacity;
        return response.data.facets;
    }

    public toggleEdit() {
        this.editMap = !this.editMap;
    }

    public editCoords() {
        this.$refs.mapSettingsModal.open(this.mapSettings);
    }

    public editImage() {
        this.$refs.mapImageModal.open(this.mapSettings);
    }

    public async markerClicked(marker: MapMarker) {
        this.loadingSelectedPlace = true;
        const place = marker.props as MapPlacePopup;
        const booking = new Booking({
            siteId: place.siteId,
            arrivalDate: this.searchParams.fromDate,
            departureDate: this.searchParams.toDate,
            source: 'Recreapi',
            channel: 'Website',
            places: [{
                placeId: place.placeId,
                placeTypeId: place.placeTypeId,
                amount: 1,
            } as BookingPlace],
            travelGroup: this.getTravelerGroup(),
        } as Booking);
        const [err, response] = await to(bookingService.calculateBookingPrice(booking, place.siteId));
        if (!err) {
            place.price = response.data;
        }
        this.selectedPlace = place;
        this.loadingSelectedPlace = false;
    }

    private async fetchInitMap() {
        const helper = new PlaceSearchHelper();
        const response = await helper.map({
            site: this.searchParams.site,
            siteOwner: this.searchParams.siteOwner,
        } as SearchPlaceTypeParamsInterface);
        this.initMapResponse = response;
    }

    private async fetchMapData() {
        this.loading = true;
        const response = await this.searchHelper.map(this.searchParams);
        this.mapResponse = response ? response : null;
        this.loading = false;
    }

    private async fetchSettings() {
        this.mapSettings = await siteService.getMapSettings(sitesModule.activeSite.siteId);
    }

    private getTravelerGroup() {
        const travelerGroup: Array<{ amount: number, TravelerTypeId: number }> = [];
        const types = bookingService.getTravelGroupTypes();
        types.forEach((type) => {
            if (this.searchParams[type.travellerType] > 0) {
                travelerGroup.push({
                    amount: this.searchParams[type.travellerType],
                    TravelerTypeId: type.type,
                });
            }
        });
        return travelerGroup;
    }
}
