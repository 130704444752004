import { SearchPlaceTypeParamsInterface } from '@/models/Interfaces';
import PlaceType from '@/models/PlaceType';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MapPlacePreviewComponent extends Vue {
    @Prop() public placeType: PlaceType;
    @Prop() public searchParams: SearchPlaceTypeParamsInterface;
    @Prop() public lotNumber: string;
    @Prop() public placeId: number;
    @Prop({ type: Boolean, default: true }) public available: boolean;

    public get image() {
        const first = this.placeType.images && this.placeType.images.length ? this.placeType.images[0] : null;
        return first ? first.url : '';
    }

    public get price() {
        return (this.placeType as any).price.calculatedPrice;
    }

    public get days() {
        return (this.placeType as any).price.totalDays;
    }

    public book() {
        const query: any = { ...this.searchParams };
        query.siteId = this.placeType.site;

        if (this.placeId) {
            query.place = `${this.placeType.placeTypeId}-${this.placeId}-${this.lotNumber}`;
        } else {
            query.placeTypeIds = JSON.stringify([this.placeType.placeTypeId]);
        }
        this.$router.push({ name: 'create-booking', query });
    }
}
