import Component from 'vue-class-component';
import PageRender from '../../models/PageRender';
import Feature from '../../models/Feature';
import { SearchPlaceTypeParamsInterface } from '../../models/Interfaces';
import { searchService, dateHelper, cacheHelper, placeSearchHelper, loginService, loginHelper } from '@/main';
import to from 'await-to-js';
import { Watch } from 'vue-property-decorator';
import { SearchResponse } from '../../models/Search/Respone';
import SearchPlaceType from '../../models/Search/PlaceType';
import { CacheKeys } from '../../helpers/cacheHelper';
import { sitesModule } from '@/store/modules/site';
import moment from 'moment/min/moment.min.js';

@Component
export default class AccommodationSearchPage extends PageRender {
    public period: any = { fromDate: null, toDate: null };
    public filterFeatures: Feature[];
    public searchParams: SearchPlaceTypeParamsInterface = {
        site: sitesModule.activeSite.siteId,
        siteOwner: loginHelper.getSiteOwner(),
        fromDate: null,
        toDate: null,
        capacity: 0,
        fromCapacity: 1,
        toCapacity: 99,
        facets: '',
        Senior: 0,
        Adult: 0,
        ChildThreeYearsAndUp: 0,
        ZeroUntilTwoYears: 0,
        Pet: 0,
        disabledAccessible: false,
        petsAllowed: false,
    };
    public isSearching: boolean = false;
    public searchTimeout: any = null;
    public searchTimeoutTime: number = 250;
    public searchResponse: SearchResponse = null;
    public availablePlaceTypes: SearchPlaceType[] = [];
    public selectedPlaceTypes: SearchPlaceType[] = [];
    public selectedFacets: any = {};
    public allFacets: any = {};
    public maxCapacity: number = null;
    public lastUpdated: string = '-';

    @Watch('searchParams', { deep: true, immediate: true })
    public watchSearchParams(val, old) {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(this.searchAvailablePlaceTypes, this.searchTimeoutTime);
    }

    public async created() {
        this.allFacets = await this.getAllFacets();
    }

    public async mounted() {
        await sitesModule.fetchSitesIfNeeded();
        this.searchParams.site = this.site.siteId;

        const cachedSearchParams = cacheHelper.getCache(CacheKeys.ACC_SEARCH_PARAMS);
        const tommorow = dateHelper.tomorrow();

        if (cachedSearchParams.site === this.searchParams.site && cachedSearchParams.siteOwner === this.searchParams.siteOwner) {
            Object.assign(this.searchParams, cachedSearchParams);
            Object.assign(this.selectedFacets, cacheHelper.getCache(CacheKeys.ACC_SEARCH_FACETS));

            if (!this.searchParams.toCapacity) {
                this.searchParams.toCapacity = 99;
            }

            this.maxCapacity = this.searchParams.toCapacity;
            this.period.fromDate = dateHelper.toDate(cachedSearchParams.fromDate);
            this.period.toDate = cachedSearchParams.toDate ? dateHelper.toDate(cachedSearchParams.toDate) : tommorow;
        } else {
            this.period.fromDate = dateHelper.today();
            this.period.toDate = dateHelper.tomorrow();
        }

        this.loadSearchStatistics();
    }

    public get site() {
        return sitesModule.activeSite;
    }

    public async getAllFacets() {
        const [err, response] = await to(
            searchService.facets({
                site: this.site.siteId,
                siteOwner: loginHelper.getSiteOwner(),
            } as SearchPlaceTypeParamsInterface),
        );

        this.setMaxCapacity(response.data.accommodationTypes);

        return response.data.facets;
    }

    public bookAccommodationTypes(placeTypeIds) {
        cacheHelper.setCache(CacheKeys.ACC_SEARCH_PARAMS, this.searchParams);
        cacheHelper.setCache(CacheKeys.ACC_SEARCH_FACETS, this.selectedFacets);

        const query: any = { placeTypeIds: JSON.stringify(placeTypeIds) };
        Object.assign(query, this.searchParams);

        this.$router.push({ name: 'create-booking', query });
    }

    public bookSelectedAccommodationTypes(placeTypes) {
        const ids = [];
        placeTypes.forEach((type) => {
            ids.push(type.placeTypeId);
        });
        this.bookAccommodationTypes(ids);
    }

    public toggleSelectedPlaceType(placeType) {
        const exists = this.selectedPlaceTypes.find((type) => {
            return type.placeTypeId === placeType.placeTypeId;
        });

        if (exists) {
            const index = this.selectedPlaceTypes.indexOf(exists);
            this.selectedPlaceTypes.splice(index, 1);
        } else {
            this.selectedPlaceTypes.push(placeType);
        }
    }

    public getNumberOfPlaces(types) {
        let numberOfPlaces = 0;

        types.forEach((type) => {
            numberOfPlaces += type.placeIds.length;
        });

        return numberOfPlaces;
    }

    private setMaxCapacity(types) {
        let biggestCapacity = 0;
        types.forEach((type) => {
            if (biggestCapacity < type.capacity) {
                biggestCapacity = type.capacity;
            }
        });
        this.maxCapacity = biggestCapacity;
    }

    private async searchAvailablePlaceTypes() {
        if (!placeSearchHelper.validateSearchParams(this.searchParams)) {
            return;
        }

        cacheHelper.setCache(CacheKeys.ACC_SEARCH_PARAMS, this.searchParams);
        cacheHelper.setCache(CacheKeys.ACC_SEARCH_FACETS, this.selectedFacets);

        this.isSearching = true;
        const [err, response] = await placeSearchHelper.searchAvailablePlaceTypes(this.searchParams);
        this.isSearching = false;

        if (response && response.data) {
            this.searchResponse = response.data;
            this.availablePlaceTypes = this.searchResponse.accommodationTypes;
        }

        if (err) {
            this.clearAndShowError(
                `Mislukt om beschikbare accommodaties op te halen. Zorg ervoor dat de volgende velden correct zijn ingevuld:
                <ul>
                    <li>Aankomstdatum</li>
                    <li>Vertrekdatum</li>
                    <li>Capaciteit</li>
                </ul>
                `,
                err,
            );
        }
    }

    private async loadSearchStatistics() {
        const [_, response] = await to(searchService.getSearchStatistics());
        this.lastUpdated = moment(response.data.LastUpdate).format('D MMMM YYYY HH:mm');
    }
}
