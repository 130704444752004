import MapMarker from '@/models/Map/MapMarker';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { LMap, LTileLayer, LMarker, LCircle, LRectangle, LControl, LImageOverlay, LIcon, LPopup, LLayerGroup } from 'vue2-leaflet';
import { Icon, LatLngBoundsExpression } from 'leaflet';
import MapImage from '@/models/Map/MapImage';

delete (Icon.Default as any).prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@Component({
    components: {
        LMap,
        LTileLayer,
        LLayerGroup,
        LMarker,
        LCircle,
        LControl,
        LImageOverlay,
        LRectangle,
        LIcon,
        LPopup,
    },
})
export default class MapComponent extends Vue {
    @Prop() public markers: MapMarker[];
    @Prop({ default: () => [[51.409541, 5.356512], [51.404497, 5.364559]] }) public bounds: LatLngBoundsExpression;
    @Prop({ default: 15 }) public zoom: number;
    @Prop({ default: () => [51.407019, 5.360536] }) public center: number[];
    @Prop({ type: Boolean }) public showResetView: boolean;
    @Prop() public image: MapImage;
    @Prop({ type: Boolean, default: true }) public showImage: boolean;
    @Prop({ type: Boolean, default: true }) public showMap: boolean;
    @Prop({ type: Boolean, default: true }) public showMarkers: boolean;
    @Prop({ default: 200 }) public tooltipWidth: number;

    public url = 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}{r}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';
    public attribution = '';
    public options = {
        zoomControl: false,
    };
    public $refs!: {
        map: LMap,
        features: any,
    };
    private resizeTimeout = null;

    @Watch('bounds')
    public watchBounds() {
        this.$refs.map.mapObject.fitBounds(this.bounds);
    }

    public mounted() {
        this.setHeight();
        window.addEventListener('resize', this.setHeight);
        this.$refs.map.mapObject.fitBounds(this.bounds);
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.setHeight);
    }

    public resetView() {
        this.$refs.map.mapObject.fitBounds(this.bounds);
    }

    public markerClicked(marker: MapMarker) {
        this.$emit('markerClicked', marker);
        this.$refs.features.mapObject.openPopup([marker.latitude, marker.longitude]);
    }

    private setHeight(): void {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            const el = this.$el;
            if (el && el.parentElement) {
                const parentHeight = el.parentElement.clientHeight;
                (this.$el as HTMLElement).style.height = parentHeight + 'px';
                this.$nextTick(() => {
                    this.$refs.map.mapObject.invalidateSize();
                });
            }
        }, 250);
    }
}
