import { ImageInterface } from '../Interfaces';

export default class MapSettings {
    public boundingBoxTopLat: number = 0;
    public boundingBoxTopLong: number = 0;
    public boundingBoxBottomLat: number = 0;
    public boundingBoxBottomLong: number = 0;
    public remoteImageId: number = 0;
    public url: string = '';
}
