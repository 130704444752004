import { ImageInterface } from '@/models/Interfaces';
import { Component } from 'vue-property-decorator';
import BaseMapSettingsModal from './base';

@Component
export default class MapImageModalComponent extends BaseMapSettingsModal {
    public source: ImageInterface[] = [];
    public edit: boolean = false;

    public async saveMapImage(images: ImageInterface[]) {
        const image = images.pop();
        this.settings.remoteImageId = image.remoteImageId;
        this.settings.url = image.url;

        await this.saveSettings();
        this.edit = false;
    }

    public cancel() {
        this.$refs.modal.hide();
        this.edit = false;
    }
}
