import { MapPlaceAvailability } from './MapPlace';
import MapPlaceType from './MapPlaceType';

export default class MapPlacePopup extends MapPlaceType {
    public placeId: number = 0;
    public lotNumber: string = '';
    public siteId: number = 0;
    public availability: MapPlaceAvailability = MapPlaceAvailability.Available;

    constructor(obj: any = {}) {
        super();
        Object.assign(this, obj);
    }
}
