import SearchPrice from '../Search/Price';
import MapPlace from './MapPlace';

export default class MapPlaceType {
    public algemeenFeatures: string = '';
    public available: number = 0;
    public buitenFeatures: string = '';
    public capacity: number = 0;
    public classification: string = '';
    public dayPriceFrom: number = 0;
    public description: string = '';
    public images: any[] = [];
    public keukenFeatures: string = '';
    public layout: string = '';
    public name: string = '';
    public placeTypeId: number = 0;
    public placeTypeKey: string = '';
    public places: MapPlace[] = [];
    public price: SearchPrice = new SearchPrice();
    public propertyTypeId: string = '';
    public propertyTypeName: string = '';
    public sanitairFeatures: string = '';
    public site: number = 0;
    public siteKey: string = '';
    public siteOwner: number = 0;
    public slaapkamerFeatures: string = '';
    public toegankelijkheidFeatures: string = '';
    public wellnessFeatures: string = '';
    public woonkamerFeatures: string = '';
}
