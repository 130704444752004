import { siteService } from '@/main';
import MapSettings from '@/models/Map/MapSettings';
import { sitesModule } from '@/store/modules/site';
import { BModal } from 'bootstrap-vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class BaseMapSettingsModal extends Vue {
    public settings = new MapSettings();
    public loading: boolean = false;
    public $refs!: {
        modal: BModal,
    };

    public open(settings: MapSettings) {
        this.settings = { ...settings };
        this.$refs.modal.show();
    }

    public async saveSettings() {
        this.loading = true;
        await siteService.saveMapSettings(sitesModule.activeSite.siteId, this.settings);
        this.loading = false;
        this.$refs.modal.hide();

        this.$emit('saved');
    }
}
