export enum MapPlaceAvailability {
    Available = 'Available',
    Booked = 'Booked',
}

export default class MapPlace {
    public placeId: number = 0;
    public availability: MapPlaceAvailability = MapPlaceAvailability.Available;
    public latitude: number = 0;
    public longitude: number = 0;
    public lotNumber: string = '';
}
